"use client";

import { api } from "@/api";
import { User } from "@/types";
import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

type Request = {
  email: string;
  password: string;
  rememberMe: boolean;
};

type Response = {
  user: User;
};

export function useSignIn(
  options: UseMutationOptions<User, Error, Request> | void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ email, password, rememberMe }) => {
      const {
        data: { user: self },
      } = await api.post<Response>("auth/signIn", {
        email,
        password,
        rememberMe,
      });

      queryClient.setQueryData(["self"], self);

      return self;
    },

    ...options,
  });
}
