import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, TextField, TextFieldProps } from "@mui/material";
import { useCallback, useState } from "react";

type Props = TextFieldProps;

export function PasswordField({ InputProps, ...props }: Props) {
  const [show, setShow] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShow((show) => !show);
  }, []);

  const PasswordIcon = show ? VisibilityOff : Visibility;

  return (
    <TextField
      type={show ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <IconButton tabIndex={-1} onClick={toggleShowPassword}>
            <PasswordIcon />
          </IconButton>
        ),

        ...InputProps,
      }}
      {...props}
    />
  );
}
