import axios, { AxiosError } from "axios";
import { dayjs } from "@/util/dayjs";
import qs from "qs";

const timeout = 5000;

// This is used on the client only
export const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_BASE_URL}/api`,
  timeout: undefined,
  timeoutErrorMessage: `Request timed out after ${timeout} ms`,

  headers: {
    common: {
      "x-platform": "web",
      "x-client": "Fire Notification",
      "x-app-version": "0.0.1",
      "x-timezone": dayjs.tz.guess(),
      crossorigin: true,
    },
  },

  paramsSerializer: (params) =>
    qs.stringify(params, {
      arrayFormat: "repeat",
      skipNulls: true,
    }),
});

api.interceptors.response.use(
  (response) => {
    const token = response.headers.authorization;

    if (token) {
      api.defaults.headers.common.authorization = token;
    }

    return response;
  },
  (error: AxiosError) => errorHandler(error),
);

function errorHandler(error: AxiosError) {
  if (["CanceledError", "AbortError"].includes(error.name)) {
    return;
  }

  console.error(error);

  // @ts-ignore
  error.originalMessage = error.message;

  // @ts-ignore
  error.message =
    error.response?.data?.message ||
    "We encountered a problem trying to process your request. Please try again.";

  return Promise.reject(error);
}
